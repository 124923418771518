<template>
<Accordion :dataObject="dsTree" :showAccordion="showAccordion">
    <ODataGrid :dataObject="dsTree"
        ref="treeGridRef"
        :hideMultiselectColumn="props.hideMultiselectColumn"
        :style="gridStyleObject"
        hideGridMenu
        hideSystemColumn
        hideActionColumn
        disableNavigation
        noDetailFooter
        :rowclickhandler="()=>{}"
    >
        <!-- Tree controls.  --> 
        <template #cardheader  v-if="filterByNodeTypes.length > 0 || showIncludeClosedToggle || hideIncludeFinishedToggle === undefined">
            <slot name="headerButtons"></slot>
            <div v-if="filterByNodeTypes.length > 0">
                <ShowButton :filterByNodeTypes="filterByNodeTypes" @fieldChanged="formFilterString"/>
            </div>
            <div class="ms-auto d-flex">
                <span v-if="hideIncludeFinishedToggle === undefined" class="ms-auto form-check form-switch d-flex gap-1 align-items-center me-3">
                    <input id="include-finished-projects" class="form-check-input me-1" type="checkbox" v-model="includeFinished" @change="includeToggle"/>
                    <label for="include-finished-projects" class="form-check-label">
                        {{ $t("Include Finished") }}
                    </label>
                </span>
                <span v-if="showIncludeClosedToggle" class="form-check form-switch d-flex gap-1 align-items-center me-3">
                    <input id="include-closed-projects" class="form-check-input me-1" type="checkbox" v-model="includeClosed" @change="includeToggle"/>
                    <label for="include-closed-projects" class="form-check-label">
                        {{ $t("Include Closed") }}
                    </label>
                </span>
            </div>
        </template>

        <slot name="treeColumns">
            <ONodeColumn :field="nodeData.displayField" :headerName="$t(nodeData.HeaderName)" boldDisplay sortable hideCount :cellTitle="row => row.NodeType">
                <template #default="{row}">
                    <OExpandableCell :row="row" :displayClass="{ 'crossed' : row.Canceled, 'closed' : row.IsClosed}">
                        <template #value>
                            <span @click="onRowClick(row)" class="cp">
                                <span  :class="{'fw-bold' : row.HasNodes, 'text-decoration-line-through' : row.Canceled, 'closed' : row.IsClosed}">
                                    {{ row[nodeData.displayField] }}
                                </span>
                                <span v-if="row.IsClosed" class="text-secondary ms-1">
                                    (Closed)
                                </span>
                                 <span v-if="row.IsFinished" class="text-secondary ms-1">
                                    (Finished)
                                </span>
                            </span>
                        </template>

                        <template #default>
                            <span :class="{ 'crossed' : row.Canceled, 'closed' : row.IsClosed }" @click="onRowClick(row)">
                                <span class="cp">
                                    {{ row[nodeData.displayField] }}
                                </span>
                                <span v-if="row.IsClosed" class="text-secondary ms-1">
                                    (Closed)
                                </span>
                                <span v-if="row.IsFinished" class="text-secondary ms-1">
                                    (Finished)
                                </span>
                            </span>
                        </template>
                    </OExpandableCell>
                </template>
            </ONodeColumn>
            <OColumn field="ID" :headerTitle="$t('ID')" :headerName="$t('ID')" hide/>
            <OColumn field="NodeType" :headerTitle="$t('Type')" :headerName="$t('Type')" hide/>
            <OColumn field="IsClosed" :headerTitle="$t('Is Closed')" :headerName="$t('Is Closed')" hide/>
            <OColumn field="IsFinished" :headerTitle="$t('Is Finished')" :headerName="$t('Is Finished')" hide />
            <OColumn field="OrgUnit_ID" :headerTitle="$t('OrgUnit ID')" :headerName="$t('Is OrgUnit ID')" hide />
            <OColumn field="Name" :headerTitle="$t('Name')" :headerName="$t('Name')" hide />
            <OColumn field="Title" :headerTitle="$t('Title')" :headerName="$t('Title')" hide />
            <OColumn field="NodeLevel" :headerTitle="$t('Node Level')" :headerName="$t('Node Level')" hide />
            <OColumn field="HasNodes" :headerTitle="$t('Has Nodes')" :headerName="$t('Has Nodes')" hide />
            <OColumn field="NamePath" :headerTitle="$t('Name Path')" :headerName="$t('Name Path')" hide />
            <OColumn field="NodeIdPath" :headerTitle="$t('Node ID Path')" :headerName="$t('Node ID Path')" hide />
            <OColumn field="SortOrder" :headerTitle="$t('Sort Order')" :headerName="$t('Sort Order')" sortable hide />
        </slot>
    </ODataGrid>
</Accordion>
</template>

<script setup lang="ts">
import $t from "o365.modules.translate.ts";
import { ref, watch, defineExpose, defineEmits } from 'vue';
import {ONodeColumn, OExpandableCell} from 'o365-nodedata';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { context } from 'o365-modules';
import { Url } from 'o365-utils';

import { createWhereClause, formFilterString, getTreeDataSource } from 'cost.vue.Tree.WbsTreeConfig.ts';
import Accordion from'cost.vue.Tree.Accordion.vue';
import ShowButton from 'cost.vue.Tree.ShowButton.vue';

const treeGridRef = ref(null);
const emit = defineEmits(['onIndexChange', 'toggleIncludeClosed', 'toggleIncludeFinished', 'rowSelected']);
let urlContext = ref(Url.getParam("Context"));
let prevWhereClause: Array<string> = [];
let hasClosedOrgUnitField = false;

const props = defineProps({
    /*
        Declare on what field to display and 
    */
    nodeData: {
        type: Object,
        default: {
            displayField: 'NameAndTitle',
            HeaderName: $t('Name')
        }
    },

    configuration: {
        type: Object,
        default: {
            type: 'hierarchy',
            idPathField: 'NodeIdPath',
            parentField: 'Parent_ID',
            additionalFields: ['ID','OrgUnit_ID' ],
            idField: 'ID'
        }
    },
    /** 
    *   Expands tree to selected level
    */
    expandToLevel: {
        type: Number,
        default:  1
    },
    /*
        If provided hides multiselect column for tree
    */
    hideMultiselectColumn: {
        type: Boolean, 
        default: true
    },
    /*
        Provide to  filter tree datasource on current context only. Need to have OrgUnit_ID field!!
    */
    showCurrentContextOnly:{
        type: Boolean,
        default: undefined,
    },
    expandByPrimKey:{
        type: String,
        default: undefined
    }, 

    /*
        Do not load datasource initialy, not sets context filter.
    */
    dontLoadDS: {
        type: Boolean,
        default: false
    },
    /*
        To show tree in Accordion navigation form
    */
    showAccordion: {
        type: Boolean,
        default: false
    },
    /*
        If added excludes added types.
        Valid NodeTypes: ['WP', 'WBS', 'OrgUnit', 'Project', 'Procurement', 'Contract', 'Bidder']
    */
    hideNodeTypes:{
        type: Array,
        default: []
    },
    /*
        If added add's item to "Show" dropdown as checbox
        Valid NodeTypes: ['WP', 'WBS', 'OrgUnit', 'Project', 'Procurement', 'Contract', 'Bidder']
    */
    filterByNodeTypes:{
        type: Array,
        default: []
    },
    /*
        Add to show slider for "Include closed" 
    */
    showIncludeClosedToggle: {
        type: Boolean,
        default: false
    },
    includeClosed:{
        type: Boolean,
        default: false
    },
    hideIncludeFinishedToggle: {
        type: Boolean,
        default: undefined
    },
    /*
       Includes Finished OrgUnits
    */
    includeFinished:{
        type: Boolean,
        default: false
    },
    /*
        Add style to tree grid 
    */
    gridStyleObject:{
        type: Object,
        default: {}
    },
    selectFirstRowOnLoad:{
        type: Boolean,
        default: true
    },
    /*
       Includes Canceled WBS
    */
    includeCanceled:{
        type: Boolean,
        default: false
    },
});

const includeClosed = ref(props.includeClosed);
const includeFinished = ref(props.includeFinished);
const dsTree = getTreeDataSource(createWhereClause(props.hideNodeTypes), props.selectFirstRowOnLoad);

defineExpose({
    includeClosed,
    includeFinished
});

if(props.showAccordion){
    props.gridStyleObject.height = '500px';
}

function setTreeFilter(){
    prevWhereClause = [];
    if(dsTree.recordSource.whereClause != ''){
        prevWhereClause.push(dsTree.recordSource.whereClause);
    }
    
    if (!prevWhereClause.includes("IsClosed = 0") && !includeClosed.value) {
         prevWhereClause.push(`IsClosed = 0` );
         prevWhereClause.push(hasClosedOrgUnitField ? 'IsClosedOrgUnit = 0': '');
    }
    
    if (!prevWhereClause.includes("IsFinished = 0") && !includeFinished.value) {
         prevWhereClause.push('IsFinished = 0');
    }

    if (!props.includeCanceled) {
         prevWhereClause.push('Canceled = 0');
    }
    
    dsTree.recordSource.whereClause = prevWhereClause.join(' AND ');
    if(!props.dontLoadDS){
        if(props.showCurrentContextOnly){
            dsTree.enableContextFilter(()=>{
                return `[OrgUnit_ID] = ${urlContext.value ?? context.id}`;
            });

        }
        else{
            dsTree.enableContextFilter(()=>{
                return `OrgUnitIdPath LIKE  '%${ urlContext.value ?? context.id}%'`
            });
        }

        // dsTree.load();
    }
}

context.on("Change", () => {
    urlContext.value = Url.getParam("Context");
    dsTree.unsetCurrentIndex();
})


useDataObjectEventListener(dsTree, 'CurrentIndexChanged', (pIndex, p2, p3)=>{
    /*
        emiting funtion after index change.
    */
    
    // Loaded twice so need to check for index to avoid that issue.
    // console.log(pIndex);
    // console.log(p2);
    // console.log(p3);
    // if(!isNaN(parseInt(pIndex))) {
        emit('onIndexChange', dsTree);
    // }

    } 
);


useDataObjectEventListener(dsTree, 'DataLoaded', async ()=>{

    /*
        emiting funtion after tree is empty.
    */

    if(dsTree.data.length <1 ){
        emit('onIndexChange');
    }

    //Expand to level
    if( props.expandToLevel && props.expandToLevel !== 1){
        dsTree.nodeData.expandToLevel(props.expandToLevel - 1);
    }

    if(props.expandByPrimKey){
        expandByPrimKey(props.expandByPrimKey);
    };

    } 
);

function enableTree() { 
    
    dsTree.nodeData.enable();
    dsTree.nodeData.addConfiguration(props.configuration);

    dsTree.nodeData.disableDetailsMultiSelect = true;

    hasClosedOrgUnitField = hasField(dsTree.recordSource.fields, 'IsClosedOrgUnit');
}

function expandByPrimKey(primKey: String){

     dsTree.nodeData.findNodeByPrimKey(primKey)?.expandTo();

}
enableTree();
setTreeFilter();


function includeToggle() {
    if (includeClosed.value) {
        prevWhereClause = prevWhereClause.filter(e => e !== 'IsClosed = 0');
        prevWhereClause = prevWhereClause.filter(e => e !== 'IsClosedOrgUnit = 0');
    } else {

        if (!prevWhereClause.includes('IsClosed = 0')) {
            prevWhereClause.push('IsClosed = 0');
        }
        if (!prevWhereClause.includes('IsClosedOrgUnit = 0') && hasClosedOrgUnitField) {
            prevWhereClause.push('IsClosedOrgUnit = 0');
        }
    }
    if (includeFinished.value) {
        prevWhereClause = prevWhereClause.filter(e => e !== 'IsFinished = 0');
    } else {
        if (!prevWhereClause.includes('IsFinished = 0')) {
            prevWhereClause.push('IsFinished = 0');
        }
    }
    let currentIndex = dsTree.currentIndex;
    dsTree.recordSource.whereClause = prevWhereClause.join(' AND ');
    dsTree.load().then(()=>{
        dsTree.setCurrentIndex(currentIndex)
    });

    emit('toggleIncludeClosed', includeClosed.value);
    emit('toggleIncludeFinished', includeFinished.value);
}



function hasField(pFields: any, pField: string) {
    for(let i = 0; i < pFields.length; i++) {
        if(pFields[i].name === pField) return true;
    }
    return false;
}

function onRowClick(item){
    dsTree.setCurrentIndex(item.index);
    emit('rowSelected');
}

</script>

<style scoped>
.cp{
    cursor: pointer;
}
.crossed *{
    text-decoration: line-through;
}
.crossed::before{
    text-decoration: line-through;
}
.closed{
    font-style: italic;
}

</style>
